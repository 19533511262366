.result_item {
  padding: 9px 16px;
  width: 100%;
  max-width: 272px;
  border-radius: 6px 6px 0 0;
}

#search_results .job {
  background-color: #fedec0 !important;
}

#search_results .contact {
  background-color: #c4efd7 !important;
}

#search_results .communication {
  background-color: #fef7cb !important;
}

#search_results .company {
  background-color: #b2e1fa !important;
}

#search_results .note {
  background-color: #eed5fe !important;
}

.default {
  background-color: #fbcac5;
}

.result_badge sup {
  box-shadow: unset;
  height: 16px;
  line-height: 16px;
  background: #f25041;
}

.job_badge sup {
  background-color: #ff9230 !important;
}
.contact_badge sup {
  background-color: #3ccc7a !important;
}
.communication_badge sup {
  background-color: #ffe753 !important;
  color: #000000 !important;
}
.company_badge sup {
  background-color: #009eef !important;
}
.note_badge sup {
  background-color: #c776ff !important;
}
.search-mentions-input{
  width: 250px; 
  white-space: nowrap; 
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}