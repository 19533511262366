* {
  margin: 0;
  padding: 0;
}

.d-flex {
  display: flex !important;
}

.mr-10 {
  margin-right: 10px;
}

.ml-1r {
  margin-left: 1rem;
}

.mt-2r {
  margin-top: 2rem;
}

.nav-item > .active,
.nav-item:hover > a {
  color: #009eef;
}

.nav-item a {
  color: #000;
  text-decoration: none;
}

.tabs-container {
  text-align: center;
  align-items: center;
}

.w-70 {
  width: 70%;
}

.nav-item {
  background-color: #fff;
  padding: 10px 28px;
  border-radius: 20px;
}

header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.timeline-container {
  overflow-y: scroll;
  height: 300px;
  padding: 10px;
}
.profile-info {
  display: flex;
  text-align: center;
  align-items: center;
  margin-right: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.titles {
  margin: 10px;
}
.ant-layout {
  background-color: #ffffff;
}
.ant-select-dropdown {
  z-index: 2000;
}
.virtual-table .ant-table-container:before,
.virtual-table .ant-table-container:after {
  display: none;
}
.virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  background: #fff;
}
[data-theme="dark"] .virtual-table-cell {
  box-sizing: border-box;
  padding: 16px;
  border-bottom: 1px solid #303030;
  background: #141414;
}
.align-center {
  align-items: center !important;
}
.w-80 {
  width: 80%;
}
.flex-start {
  justify-content: flex-start;
}
.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.name-input .ant-input {
  border: none !important;
}
.w-100 {
  width: 100%;
}
.ant-timeline-item-label {
  margin-bottom: 0.6rem;
}
.cursor-pointer {
  cursor: pointer;
}
.ant-table-title:empty {
  display: none;
}
.ant-btn-primary {
  background-color: #009eef;
  border-color: #009eef;
  border-radius: 6px;
}
.ant-btn-primary:hover {
  background-color: #12afff;
  border-color: #12afff;
}
.ant-btn:hover {
  border-color: #12afff;
}
.ant-radio input {
  color: #009eef;
}
.ant-layout-header {
  background-color: #ffffff;
  height: 64px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.04);
  z-index: 1000;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #009eef !important;
}
.ant-radio-inner::after {
  background-color: #009eef;
}

.ant-list:not(:first-child) {
  overflow-y: scroll;
  height: calc(100vh - 500px);
}
#todo_list .ant-divider-vertical {
  border-left: 1px solid #000 !important;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding-left: 18px !important;
}

.bread-crumb:hover {
  color: #009eef !important;
  cursor: pointer;
}

/* START HEADER */
.logo {
  margin-right: 50px;
  padding-top:25px;
}
.profile-username {
  margin-right: 16px;
  color: #000000;
}
.menu-item-button {
  padding: 0;
}
.header-menu {
  margin-right: auto;
}
.header .ant-btn.ant-btn-primary {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.d-flex.header-search-btn {
  margin-right: 50px;
}
.header .ant-input-wrapper.ant-input-group {
  width: 270px;
}
.header .ant-input-wrapper.ant-input-group input {
  height: 32px;
}
.header .ant-menu-item {
  background-color: #ffffff;
  padding: 0px 20px;
  border-right: 1px solid #f0f0f0 !important;
}
.header .ant-menu-item a {
  color: #343434 !important;
  font-weight: 500;
}
.header .ant-menu-item a:hover {
  color: #ffffff !important;
}
.header .ant-menu-item-selected a {
  color: #ffffff !important;
}
.first-menu-item {
  border-left: 1px solid #f0f0f0 !important;
}
.first-menu-item:hover {
  border-left: 1px solid #f0f0f0 !important;
  background-color: #f25041 !important;
}
.first-menu-item:hover,
.ant-menu-item-selected.first-menu-item {
  background-color: #f25041 !important;
}
.two-menu-item:hover,
.ant-menu-item-selected.two-menu-item {
  background-color: #ff9230 !important;
}
.three-menu-item:hover,
.ant-menu-item-selected.three-menu-item {
  background-color: #28B6B6 !important;
}
.four-menu-item:hover,
.ant-menu-item-selected.four-menu-item {
  background-color: #009eef !important;
}
.five-menu-item:hover,
.ant-menu-item-selected.five-menu-item {
  background-color: #c776ff !important;
}
/* END HEADER */

/* START CONTACTS */
.contact-container .ant-table-cell::-webkit-scrollbar {
  width: 0;
}
.contact-container .ant-table-cell {
  white-space: nowrap;
  overflow: overlay;
}
.contact-container {
  padding: 0px 24px;
  margin-top: 44px;
  position: relative;
}
.contact-title {
  margin-right: auto;
  margin-bottom: 0px !important;
}
.contact-subtitle {
  margin-right: unset;
}
.contact-container .ant-space-item {
  margin-right: 0px !important;
}
.contact-container .ant-table-cell {
  padding: 8px 16px !important;
}
.contact-container .ant-table-column-sorters {
  padding: 0px 0px !important;
}
.contact-container .anticon.anticon-delete {
  color: #f25041 !important;
}
.contact-modal.inpunt.name {
  height: 32px;
  font-size: 22px !important;
  width: 324px;
  margin-right: 15px;
}
.contact-modal.inpunt.notes {
  height: 98px;
  margin-bottom: 24px;
  width: 324px;
}
.contact-modal.inpunt {
  width: 407px;
}
.contact-modal.inpunt.small {
  width: 198px;
}
.contact-modal.title.small {
  width: 198px;
}
.contact-modal.right.inpunt {
  width: 324px;
}
.contact-modal.right.inpunt.mobile {
  width: 198px;
  margin-right: 126px;
}
.contact-modal.inpunt.location {
  margin-right: 11px;
}
.contact-modal.title.name {
  margin-top: 24px;
}
.contact-modal.right.title.mobile {
  margin-top: 12px;
}
.ant-input.tag {
  margin-bottom: 24px;
}
.contact-modal.title {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
  margin-top: 12px;
  font-weight: 400;
}
.contact-modal.right.title {
  text-align: left;
  margin-left: 21px;
}
.contact-modal.tag-input {
  width: 78px;
  margin-right: 8px;
  vertical-align: top;
  height: 22px;
}
.contact-modal.tag {
  margin-bottom: 5px;
}
.contact-modal.tag-plus {
  background: #fff;
  border-style: dashed;
  margin-left: auto;
  margin-right: 0px;
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0px !important;
}
.contact-modal.auto-complete {
  width: 324px;
}
.contact-modal.auto-complete .ant-select-selector {
  padding: 4px 11px;
}
.contact-modal.auto-complete
  .ant-select-selector
  input.ant-select-selection-search-input,
.contact-modal.auto-complete
  .ant-select-selector
  span.ant-select-selection-placeholder {
  height: 28px;
}
.contact-modal.title.dates {
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px 0px 17px 0px;
}
.contact-modal.title.name-input {
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.6);
  margin: 0px 0px 34px 0px;
}
.contact-modal.title.editing {
  position: absolute;
  bottom: 0;
  right: 0;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}
.contact-modal.communications .anticon.anticon-delete {
  color: #f25041;
  margin-left: auto;
  height: 15.47px;
}
.contact-modal.communications {
  height: 328px;
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  border-radius: 7px;
}
.contact-modal.communications-title {
  margin-bottom: 0px;
}
.contact-modal.todo-title {
  margin-top: 24px;
  margin-bottom: 13px;
}
.contact-modal.todo .ant-list {
  height: 180px;
}
.contact-modal.todo {
  margin-right: 24px;
}
.contact-modal.inpunt.notes.workspace {
  margin-bottom: 24px;
  height: 69px;
  width: 383px;
  margin-right: 24px;
}
.contact-modal.tag span {
  color: rgba(0, 0, 0, 0.85);
}
.contact-modal.tag {
  margin-bottom: 32px;
  width: 376px;
}
ul.tags_list {
  margin-bottom: 4px;
}
.contact-modal.input-tag {
  height: 22px;
  width: 160px;
}
.select_color {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 10px;
  position: fixed;
  z-index: 999;
}
.select_color li {
  margin-bottom: 5px;
}
.select_color ul {
  margin-bottom: 0px;
  justify-content: space-between;
  display: flex;
}
.select_color .delete-button {
  border: none;
  padding: 0;
  height: 22px;
  box-shadow: none;
  margin-right: auto;
}
.select_color .delete-button span {
  color: #f25041 !important;
}
.contact-modal .tag-plus {
  background: #fff;
  border-style: dashed;
  border-radius: 50px;
}
.contact-modal.tag-color span {
  width: 10px;
  height: 10px;
}
.contact-modal.tag-color span.anticon.anticon-check {
  width: 16px;
  height: 16px;
}
.contact-modal.tag-color svg {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  margin-top: 6px;
}
.ant-popconfirm .ant-popover-buttons button {
  height: 24px;
  border-radius: 6px;
}
.select_color.tag-add {
  display: flex;
}

.contact-modal.share-icon .anticon.anticon-link svg {
  height: 16px;
  width: 16px;
  margin-right: 12px;
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
}
.contact-modal.share-icon .anticon.anticon-linkedin svg {
  height: 32px;
  width: 32px;
}
.contact-modal.inpunt.share-inpunt {
  width: 276px;
}
.contact-modal.title.tags {
  margin: 0px;
}
.contact-modal .add_todo_container {
  padding: 0px;
}
.contact-modal .ant-input,
.contact-modal .ant-picker {
  font-size: 14px !important;
}
.contact-modal .todo-group,
.contact-modal .todo-date {
  display: none;
}
.contact-modal .ant-picker-input #date-picker {
  width: 59px;
}
.contact-modal .ant-picker.ant-picker-focused {
  border-radius: 6px;
}
.contact-modal
  .ant-list.ant-list-lg.ant-list-split.ant-list-bordered
  .ant-list-items
  div
  li {
  padding: 8px 12px !important;
  border-bottom: 1px solid #f0f0f0;
}
.contact-modal .ant-picker:hover,
.contact-modal .ant-picker-focused {
  border-right-width: 1px !important;
  border-color: #009eef;
}
.ant-picker {
  cursor: pointer;
  margin-left: auto;
  min-width:83px;
  height: 24px;
  border-radius: 6px;
  justify-content: center;
  text-align: center;
  border: 1px solid #d9d9d9;
}
.create-todo-picker{
  cursor:pointer;
  min-width: 100px;
  border-radius: 6px;
  height: 24px;
  position: absolute;
  right: 5px;
  top:40px;
  padding-top: 2px;
}

.create-modal-todo-picker {
  cursor: pointer;
  min-width: 100px;
  border-radius: 6px;
  height: 22px;
  position: absolute;
  right: 3px;
  top: 3px;
  padding-top: 2px;
}

.ant-modal .ant-modal-close-x{
  font-size: 20px;
}
.docs_modal .ant-modal-close-x{
  line-height: 0px !important;
}
/* .ant-btn.ant-btn-round.ant-btn{
  border-radius: 7px;
} */
.ant-picker-cell-inner::before {
  border: 1px solid #009eef !important;
}
.contact-modal .ant-picker-input #date-picker {
  width: 59px;
}
.contact-modal .anticon.anticon-delete {
  color: #f25041 !important;
}
.contact-modal .list-item-text::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.contact-modal .w-100 {
  width: 93%;
}

.contact-modal .todo-check {
  margin-right: 12px;
}
.contact-modal.communications.list
  .ant-list-items
  li.ant-list-item.docs-first-list-item {
  padding-bottom: 0px !important;
  border-bottom: 1px solid #f0f0f0;
}
.contact-modal.communications.list .docs-note-item {
  border: unset;
}
.contact-container .no-data {
  height: 151px;
}
.contact-container .no-data .ant-empty-image {
  margin-top: 30px;
}
.contact-modal.communications.list .notes-items .anticon.anticon-edit {
  margin-right: unset !important;
}

/* END CONTACTS */

/* START LEADS */

.contact-title.lead {
  margin-right: 0;
}

.contact-container.lead .ant-radio-button-checked {
  background: #009eef;
  border-color: #009eef;
}

.contact-container.lead .ant-switch {
  background: #bfbfbf;
}

.contact-container.lead .ant-switch.ant-switch-checked {
  background: #009eef;
}

.contact-container.lead
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
  color: #ffffffea;
}

.lead-switcher  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover {
color: #ffffff;
}

.contact-container.lead .ant-radio-button-wrapper:hover {
  color: #009eef;
}
.contact-modal.inpunt.notes.description {
  width: 407px;
  margin-bottom: 4px;
  height: 118px;
}
.contact-modal.right.title.resume {
  margin-top: 0px;
  margin-bottom: 0px;
}
.contact-modal.inpunt.right.notes.leads {
  width: 324px;
  margin-left: 21px;
  margin-bottom: 4px;
  height: 84px;
}

.contact-modal.inpunt.right.notes.leads.workspace {
  height: 118px;
}
.lead-modal.card .ant-card-body {
  padding: 0px 6px;
  width: 112px;
  text-align: center;
}
.lead-modal.card {
  margin-left: auto;
}
.contact-modal.title.desc {
  margin: 0px;
}
.contact-modal.share-icon.lead .anticon.anticon-link svg {
  height: 16px;
  width: 16px;
  margin-right: 0px;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.85);
}
.contact-modal.share-icon.lead {
  margin-left: auto;
  align-items: center;
}
.lead-modal.key-dates .ant-card-body {
  padding: 0px 8px 0px 0px;
}
.contact-modal.auto-complete.lead {
  width: 298px;
}
.contact-modal.btn-icon svg {
  width: 16px;
  height: 16px;
  margin-bottom: 4px;
  color: #009eef;
}
.contact-modal.btn-icon {
  margin-left: 10px;
}
.contact-modal.question-icon svg {
  width: 16px;
  height: 16px;
  color: #bfbfbf;
}
.contact-modal.resume-icon svg {
  width: 16px;
  height: 16px;
  color: #bfbfbf;
}
.contact-modal.resume-icon.close svg {
  color: #bfbfbf;
}
.resume-modal .upload-icon svg {
  width: 16px;
  height: 16px;
}
.resume-modal .right-icon svg {
  width: 24px;
  height: 24px;
  margin-top: 4px;
}
.resume-modal .ant-upload-list.ant-upload-list-text {
  display: none;
}
.resume-modal .ant-upload-list-item-info {
  padding: 0 !important;
}
.modal-resume {
  top: 188px;
  width: 400px !important;
}
.lead-modal.key-dates {
  font-size: 12px;
}
.modal-leads.description-icon {
  width: 20px;
  height: 20px;
}
.description-icon svg {
  margin-bottom: -1.5px;
}
.lead-modal.switch.ant-switch-checked {
  background-color: #009eef;
}
.contact-modal.todo .todo-group {
  display: none !important;
}
.lead-modal.description.edit-icon {
  margin-left: 9px;
}
.contact-container.lead .ant-radio-button-checked {
  border-radius: inherit;
}
.contact-container.lead .no-data {
  height: 151px;
}
.contact-container.lead .no-data .ant-empty-image {
  margin-top: 30px;
}
.contact-modal.todo .no-data {
  height: 180px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  overflow-y: scroll;
  margin: 0;
}
.contact-modal.todo .no-data .ant-empty-image {
  margin-top: 50px;
}
.contact-modal.communications.list .no-data .ant-empty-image {
  margin-top: 88px;
}
/* END LEADS */

/* START DESKTOP */
.desktop-container {
  padding: 0px 24px;
  margin-top: 24px;
  position: relative;
}
.desktop-container .table-block.left {
  padding-right: 12px;
}
.desktop-container .table-block.right {
  padding-left: 12px;
}
.desktop-container .table-block {
  height: 344px;
}
.desktop-container .desktop-title {
  line-height: 0.8;
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.desktop-container .ant-input,
.desktop-container .ant-picker,
#date-picker {
  font-size: 14px !important;
}
.desktop-container .ant-picker.ant-picker-focused {
  border-radius: 6px;
}
.desktop-container .ant-radio-button-checked {
  background: #009eef;
  border-color: #009eef;
}
.desktop-container .ant-radio-button-wrapper:hover {
  color: #009eef;
}
.desktop-container .ant-radio-group.ant-radio-group-solid {
  display: flex;
}
.desktop-container .todo {
  width: 100%;
  margin-left: 0;
}
.desktop-container .ant-list.ant-list-lg.ant-list-split.ant-list-bordered {
  height: 100%;
}
.desktop-container .ant-list:not(:first-child) {
  overflow-y: unset;
}
.desktop-container
  .ant-list.ant-list-lg.ant-list-split.ant-list-bordered
  .ant-list-items
  div
  li {
  padding: 8px 12px !important;
  border-bottom: 1px solid #f0f0f0;
}
.desktop-container
  .ant-list.ant-list-lg.ant-list-split.ant-list-bordered
  .ant-list-items
  div:last-child
  li {
  border-bottom: 0px;
}
.desktop-container .ant-picker:hover,
.desktop-container .ant-picker-focused {
  border-right-width: 1px !important;
  border-color: #009eef;
}
.ant-picker-cell-inner::before {
  border: 1px solid #009eef !important;
}
.desktop-container .ant-picker-input #date-picker {
  width: 59px;
}
.desktop-container .anticon.anticon-delete {
  color: #f25041 !important;
}
.anticon.anticon-delete.custom-delete-color {
  color: #ffffff !important;
}
.desktop-container .list-item-text::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.desktop-container .w-100 {
  width: 97%;
}
.desktop-container .todo-list.title-icon svg {
  width: 14px;
  height: 14px;
  transition: transform 0.24s;
  margin-top: -2px;
}
.desktop-container .todo-check .ant-checkbox {
  display: block;
  top: 0px;
}
.desktop-container .list-item.date-picker.ant-picker,
.desktop-container .list-item.date-picker.ant-picker-focused {
  width: 83px;
}
.desktop-container .ant-picker-input input {
  font-size: 14px !important;
}
.desktop-container .todo-check {
  margin-right: 24px;
}
.desktop-container h3.desktop-title {
  margin-top: 0px;
  margin-bottom: 0px;
}
.desktop-container .desktop-timeline ul {
  overflow-y: scroll;
  max-height: 307px;
  padding-top: 12px;
  overflow-x: hidden;
}
.desktop-container .desktop-timeline ul::-webkit-scrollbar-x {
  width: 0;
  height: 0;
}
.desktop-container .desktop-timeline sup.ant-badge-multiple-words {
  padding: 0 6px;
}
.desktop-container .desktop-timeline .ant-badge-count {
  padding: 0 8px;
}
.desktop-container .ant-table-cell div::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.desktop-container h5.desktop-title.showcase {
  margin-top: 4px !important;
}
.desktop-container .timeline-header sup.ant-badge-count {
  height: 17px;
  min-width: 16px;
  line-height: 16px;
  padding: 0 6px;
}
.desktop-container li.ant-timeline-item {
  padding-bottom: 12px;
}
.desktop-container
  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked
  span {
  border-radius: 6px 6px 6px 6px;
  
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radio-new  {
  border: 1px solid #009eef;
  background-color: #009eef;
}
 .ant-radio-button-wrapper.ant-radio-button-wrapper-checked.radio-active {
  border: 1px solid #009eef;
  background-color: #009eef;
  border-left:none;
}

.desktop-container
  .ant-table-container
  .ant-table-body
  .ant-table-tbody
  .ant-table-placeholder
  div.ant-empty.ant-empty-normal {
  margin: 85px 0;
}
.desktop-todo.actions div.ant-picker.ant-picker-focused {
  height: 24px;
}
.diary-details span {
  margin-left: auto;
}

.desktop-timeline .ant-empty.no-data {
  height: 307px;
  overflow-y: scroll;
  margin: unset;
}
.desktop-container .desktop-timeline .no-data .ant-empty-image {
  margin-top: 127px;
}
.desktop-container .table-block.right .desktop-todo .no-data .ant-empty-image {
  margin-top: 82px;
}
/* END DESKTOP */

/* START COMPANY */
.company-modal.contact {
  font-size: 24px;
  line-height: 1.35;
  margin-bottom: 0px;
}
.contact-modal.table .ant-table-cell {
  padding: 8px 16px !important;
}
.contact-modal.table .ant-table-placeholder td {
  height: 348px;
}
.contact-modal.share-icon .anticon.anticon-global svg {
  height: 26px;
  width: 26px;
  margin-top: 2px;
}
.company-modal.delete-icon svg {
  margin-bottom: 3px;
}
.company-modal .scroll-vertical .ant-empty {
  margin-top: 167px;
}
.company.edit-note-modal .contact-modal.tag div.modal-tags,
.company.edit-note-modal div.contact-modal.tag {
  width: 564px !important;
}
.company.edit-note-modal div.contact-modal.tag,
.company.edit-note-modal .contact-modal.tag div {
  margin-bottom: 0 !important;
}
.company.edit-note-modal .contact-modal.tag {
  margin: 12px 24px 0 24px !important;
}
.company-modal
  .scroll-vertical
  div.company-modal.card-links::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.company-modal .scroll-vertical div.ant-card-head-title {
  padding: 9px 0 !important;
  margin-bottom: 2px;
}
.company-modal .scroll-vertical div.ant-card-extra {
  padding: 9px 0 !important;
}
.company.edit-note-modal div.link-text::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.company.edit-note-modal ul.tags_list {
  margin-bottom: 26px;
}
.company-modal.notes-table .ant-table-placeholder {
  height: 340px;
}
.company-updates .docs-note-item .note-title {
  margin-right: 16px;
  max-width: 200px;
}
.company-updates .docs-note-item .note-title.title-full-width {
  max-width: unset;
}
.company-updates .notes-items__links,
.company-updates .notes-items__atts {
  max-width: 165px;
  min-width: 10%;
}
/* END COMPANY */

.add_todo_container input {
  border: none;
}
.add_todo_container input:active,
.add_todo_container input:focus {
  border: none !important;
}
.low_priority {
  border: 1px solid #3ccc7a !important;
}
.medium_priority {
  border: 1px solid #ff9230 !important;
}
.high_priority {
  border: 1px solid #f25041 !important;
}
.select_file_type_dropdown {
  color: #000;
}
.select_file_type .ant-select-selection-item {
  color: #000 !important;
}
.ant-popover {
  z-index: 9999;
}
.d-none {
  display: none;
}
.align-items-center {
  align-items: center !important;
}
#diary_item .ant-badge {
  margin-left: 1rem;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #009eef;
}
.ant-tabs-tab:not(.ant-tabs-tab-disabled):hover {
  color: #009eef;
}

.ant-table-cell {
  white-space: nowrap;
  overflow: overlay;
}
.ant-table-cell::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.ant-switch-checked {
  background-color: #009eef !important;
}
.ant-btn-dangerous.ant-btn-primary {
  background: #f25041;
  border-color: #f25041;
}
.ant-table-cell div::-webkit-scrollbar {
  width: 0;
  height: 0;
}
h6.ant-typography,
.ant-typography h6,
h5.ant-typography,
.ant-typography h5,
h4.ant-typography,
.ant-typography h4,
h3.ant-typography,
.ant-typography h3,
h2.ant-typography,
.ant-typography h2,
h1.ant-typography,
.ant-typography h1 {
  font-weight: 500 !important;
}
.desktop-container .no-data {
  height: 251px;
}
.desktop-container .desktop-table.hopper .no-data {
  height: 243px;
}
.desktop-container .desktop-table.hopper .no-data .ant-empty-image {
  margin-top: 76px;
}
.desktop-container .no-data .ant-empty-image {
  margin-top: 80px;
}
/*TODO*/
.ant-modal-wrap {
  z-index: 1001;
  top: -35px;
}

.custom-tab {
  margin: 0;
  display: flex;
  align-items: center;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 2px 2px 0 0;
  cursor: pointer;
  list-style: none;
}
.custom-tab:hover {
  color: #009eef !important;
}
.custom-tab.ant-tabs-tab-active span {
  color: #1890ff !important;
}

.ant-popover {
  z-index: 1001;
}

/* authForm */
.authForm input#email_form {
  outline: unset !important;
  padding-left: 8px;
  padding-right: 8px;
}
.authForm input#form_password {
  outline: unset !important;
  padding-left: 8px;
  padding-right: 8px;
}

.google-login .ant-btn-primary {
  border-color: #d9d9d9;
}
.google-login .ant-btn-primary:hover {
  border-color: #d9d9d9;
}

.ant-drawer-body {
  position: relative;
}

/* START LIBRARY */
.docs-tagfilter div .ant-space .ant-space-item {
  margin-right: 0px !important;
  margin-bottom: 8px;
}
.docs-tagfilter div {
  align-items: flex-start !important;
}
.docs-tagfilter div::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.contact-container {
  padding: 0px 24px;
  margin-top: 44px;
  position: relative;
}
span.docs-tabs-plus svg {
  color: rgba(0, 0, 0, 0.55);
  width: 16px;
  height: 16px;
  margin-top: 6px;
}
#root {
  height: 100%;
}
.ant-tabs-top > .ant-tabs-nav.docs::before,
.ant-tabs-bottom > .ant-tabs-nav.docs::before,
.ant-tabs-top > div > .ant-tabs-nav.docs::before,
.ant-tabs-bottom > div > .ant-tabs-nav.docs::before {
  border-bottom: unset;
}
.docs-close-icons span.anticon.anticon-close {
  margin-right: 0px;
  margin-left: 10px;
}
.docs-close-icons span.anticon.anticon-close svg {
  width: 12px;
  height: 12px;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.55);
}
.docs-tagfilter .ant-empty-image svg {
  width: 100%;
}
.ant-list.docs-notes::-webkit-scrollbar {
  width: 0;
}
.ant-list.docs-notes ul.ant-list-items li.ant-list-item.docs-first-list-item {
  border-bottom: unset;
  padding: 0 0 9px 0 !important;
}
.ant-list.docs-notes
  ul.ant-list-items
  li.ant-list-item.docs-first-list-item
  .docs-note-item {
  padding: 9px 16px !important;
  border-radius: 6px;
}

.docs-note-item {
  border: 1px solid #f0f0f0;
  background-color: #ffffff;
  max-width: 100%;
}

.docs-notes-tags .ant-space .ant-space-item {
  margin-right: 0px !important;
}
.docs-notes-tags .ant-space .ant-space-item .ant-tag {
  margin-right: 8px !important;
}
.docs-notes-tags {
  margin-right: 8px;
  min-width: 50px;
}
.notes-items span::-webkit-scrollbar,
.notes-items div::-webkit-scrollbar {
  width: 0;
  height: 0;
}
div.docs-links div span {
  text-decoration: none !important;
  font-weight: 400 !important;
}
.docs-modal-tags div.contact-modal.tag {
  width: 100%;
}
.docs-modal-tags div.contact-modal.tag div.taggs {
  display: flex;
  flex-direction: row-reverse;
  margin-right: auto;
  flex-wrap: wrap;
}
.docs-modal-tags div.contact-modal.tag .ant-tag.ant-tag-has-color {
  margin-bottom: 0px !important;
}
.docs-modal-tags div.contact-modal.tag .modal-tags {
  width: unset !important;
  margin-top: unset !important;
  margin-bottom: unset !important;
  margin-right: auto;
}
.docs-modal-tags .contact-modal.tag div {
  margin-bottom: 9px !important;
}
.docs-modal-tags .contact-modal.tag {
  margin-bottom: 9px !important;
}
.docs_modal .ant-modal-body {
  padding: 8px 24px;
}
.docs_modal .ant-modal-header {
  padding: 18px 24px;
}
.docs-chip .chips {
  margin: unset !important;
  padding: unset !important;
  border: unset !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.docs-chip .chips .chips-chip {
  margin-right: 9px;
  border-radius: 50px;
  border: 1px solid #d9d9d9;
  padding: 2px 4px 2px 7px !important;
  height: 22px;
  margin-bottom: 6px;
}
.docs-chip .chips .chips-chip .chip-icon {
  margin-right: 11px !important;
}
.docs-chip .chips .link-text::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.docs-chip .chips .link-date::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.docs-chip .chips .link-date {
  margin-right: 6px !important;
}
.docs-chip .chips .link-text span {
  text-decoration: unset !important;
  font-weight: 400 !important;
}
.docs-chip .chips .link-text {
  margin-right: 6px !important;
  margin-left: -6px;
}
.docs-custom-tabs {
  max-width: 100%;
  overflow-x: scroll;
}

.flex-row {
  flex-direction: row;
}

.docs-custom-tabs input.ant-input.ant-tabs-tab {
  height: 39px;
  border-color: #009eef;
  color: #009eef;
  outline-color: #009eef;
}
.docs-custom-tabs::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.ant-tabs-nav.docs .ant-tabs-tab-active {
  border-bottom-color: #f0f0f0 !important;
}
.docs-chip {
  width: 50%;
  overflow-x: scroll;
}
.docs-chip::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.docs_modal .ant-modal-footer {
  padding: 0;
}
.library-container .no-data {
  margin-top: 150px;
}
/* END LIBRARY */

/* START SEARCH RESULT */
.search-column-item::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.search-column-item {
  border-right: 1px solid rgb(240, 240, 240);
  border-left: 1px solid rgb(240, 240, 240);
}
.search-column-item-x::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.search-results :last-child {
  margin-right: unset !important;
}
.search-results .result .result-column {
  overflow-y: scroll;
  max-height: 630px;
}
.search-results .result .result-column::-webkit-scrollbar {
  width: 0;
}
.search-results .content-text {
  white-space: nowrap;
  max-width: 272px;
  overflow-x: scroll;
}
.search-results .content-text::-webkit-scrollbar {
  width: 0;
  height: 0;
}
/* END SEARCH RESULT */

.ql-container {
  min-height: 30vh;
}
.ant-table-tbody td.ant-table-cell {
  background: #ffffff;
}

.ant-tag {
  line-height: 19px !important;
}
.link {
  font-weight: 500;
  color: #009eef;
  cursor: pointer;
}

.flex-column {
  flex-direction: column;
}

.badge-icon {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  margin-right: 3px;
}

.diary_drawer_date {
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
  margin-left: 16px;
  text-align: end;
  margin-right: 23px;
  margin-top: 3.2px;
}
.diary_drawer_date.large-date {
  margin-top: unset;
}

/* START DIARY */
.diary-activities-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.diary-activities-container .title {
  display: flex;
  margin: 12px 0 6px 0px;
  align-items: center;
}
.diary-details .anticon-caret-down {
  display: none;
}
.diary-list .ant-list-items li.ant-list-item {
  padding: 0 !important;
}
.diary-list {
  overflow-y: unset !important;
  border: 1px solid #f0f0f0;
  border-bottom: 0;
  width: 100%;
  height: 100% !important;
}
.diary_drawer .ant-drawer-body {
  overflow-x: scroll;
}
.diary_drawer .ant-drawer-body::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.diary_tags div::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.diary_tags span.ant-tag-has-color {
  margin-right: unset !important;
}
.diary_details_text .ant-typography pre {
  background: unset;
  border: unset;
  padding: unset;
  max-width: 100%;
  white-space: nowrap;
  overflow: unset;
  margin: unset;
  margin-bottom: unset !important;
}
.diary_details_text::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.edit-header {
  display: flex;
  position: absolute;
  top: 13px;
  right: 5px;
  align-items: center;
  padding: 0 8px;
}
.edit-header.comm {
  position: relative;
  top: unset;
  right: unset;
  align-items: unset;
  padding: unset;
}
.edit-header.comm.is-edit {
  margin-right: -4px;
}
.edit-header.comm.details {
  margin-left: auto;
}
.largeNotes .noteTags::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.diary_details_text #quill_editor {
  max-width: 517px;
  width: 100%;
}
.diary-edit-btn {
  height: 22px;
  padding: 0 3px;
  border-radius: 6px;
}
.diary-edit-btn svg {
  margin-bottom: 1px;
  width: 14px;
}
.diary-edit-btn.close {
  margin-right: 6px;
}
.edit-comm-header {
  margin-bottom: 7px;
  justify-content: space-between;
}
.edit-comm-footer {
  margin-top: 8px;
  align-items: center;
}
.edit-comm-date .diary_drawer_date {
  margin-right: -1px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px !important;
}
.docs_modal .ant-modal-close-x {
  line-height: 50px;
}
.docs_modal.diary_editor .ant-modal-header {
  border-bottom: unset;
  padding: 16px 24px;
}
.docs_modal.diary_editor .ant-modal-body {
  padding-top: unset;
}
/* END DIARY */

.library-container {
  padding: 12px 28px;
  background-color: #fefefe;
  height: 100%;
}

.library-tags-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#notes .docs-notes {
  height: 100%;
  max-height: 76vh;
}

.workspace-tab {
  margin-right: 4px;
  border-radius: 6px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.workspace-add-button {
  width: 46px;
  height: 40px;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  box-shadow: unset;
}

.workspace-draggable-tab {
  color: rgba(0, 0, 0, 0.65);
}

.can-drop {
  background-color: red !important;
}

.can-drop:hover {
  background-color: green !important;
}
.diary_editor .ant-modal-body {
  padding-top: 56px;
}

.docs-note-item .notes-items::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.docs-note-item .note-title {
  text-align: left;
}
.ant-tabs-tab.workspace_container input {
  height: 23.5px !important;
  padding-top: unset;
  padding-bottom: unset;
  margin-top: -2.5px;
}
.url-title {
  line-height: 0.8;
  margin-top: 0px !important;
  margin-bottom: 0px;
}
.btn-style {
  border-color: #009eef;
  color: #009eef;
}
.btn-style-wh {
  border-color: #009eef;
  background-color: #009eef;
  color: #ffffff;
}

.contact-modal-create-todo{
  top:10px;
  right: 10px;
}
.ant-modal-content{
  padding: 0px !important;
}
.ant-modal-body {
  padding: 20px !important;
}
.docs_modal.note_editor .ant-modal-body{
  padding: 0px 24px 14px 24px !important;

}
.docs_modal.note_editor .ant-modal-close{
  top: 18px;
}
.add-communication-modal .ant-modal-body{
  padding: 0px 0px 20px 20px !important;
}

.add-file-modal .ant-modal-body{
  padding: 136px 141px 1px 144px !important;
}
.add-file-modal .ant-upload-btn {
  padding: 0px !important;
}
/* START DELETE-MODAL COMPANY */
.delete-modal .ant-modal-body,
.delete-modal .ant-modal-content {
  width: 377px;
  min-height: 159px;
}
.delete-modal .delete-modal__content {
  display: flex;
  align-items: center;
}
.delete-modal .delete-modal__img img {
  width: 75px;
  height: 75px;
}
.delete-modal .delete-modal__text {
  display: flex;
  flex-direction: column;
  margin-left: 21px;
}
.delete-modal .ant-modal-close {
  display: none;
}
.delete-modal .delete-modal__title,
.delete-modal .delete-modal__subtitle {
  font-size: 16px;
}
.delete-modal .delete-modal__title {
  font-weight: 500;
}
.delete-modal__actions .btn-cancel {
  margin-right: 16px;
  margin-left: auto;
}
.delete-modal__actions button {
  min-width: 100px;
}
.delete-modal__actions {
  width: 100%;
  display: flex;
  margin-top: 4px;
}
.delete-modal.company {
  top: 46%;
}
/* END DELETE-MODAL COMPANY */

/* START GENERAL CLASSES */
.df {
  display: flex;
}
.d_f {
  display: none;
}
.al-it_c {
  align-items: center;
}
.jc_sb {
  justify-content: space-between;
}
.fd_col {
  flex-direction: column;
}
.ml_21 {
  margin-left: 21px;
}
.ml_a {
  margin-left: auto;
}
.mt_16 {
  margin-top: 16px;
}
.fw_500 {
  font-weight: 500;
}
/* END GENERAL CLASSES */

/* START CHANGE-PASSWORD MODAL */
.change-password-modal .ant-modal-body,
.change-password-modal .ant-modal-content {
  width: 413px;
  min-height: 171px;
}
.change-password-modal__image {
  width: 75px;
  height: 75px;
}
.change-password-modal {
  top: 20%;
}
/* END CHANGE-PASSWORD MODAL */

/* START DELETE-WORKSPACE MODAL */
.new-modals-subtitle {
  font-size: 16px;
}
.new-modals-title {
  font-size: 16px;
  font-weight: 500;
}
.delete-workspace-modal .ant-modal-body,
.delete-workspace-modal .ant-modal-content {
  width: 377px;
  min-height: 159px;
}
.delete-lead .ant-modal-body,
.delete-lead .ant-modal-content {
  width: 377px;
  min-height: 159px;
}
.delete-workspace-modal {
  top: 12%;
}
/* END DELETE-WORKSPACE MODAL */

.communications .docs-note-item {
  width: 100%;
}

.communications .notes-items {
  width: 100%;
}

.communications .ant-typography {
  margin-left: auto !important;
}

.lead-view {
  margin-top: 1rem;
}

#todo_list.ant-list-bordered {
  border-color: #f0f0f0;
  border-radius: 7px;
}

.custom-closeicon img {
  width: 16px;
  height: 16px;
}

.disabled-row {
  color: grey;
  pointer-events: none;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  font-size: 1rem;
}

/* START NO DATA */
.no-data .ant-empty-image {
  width: 66px;
  height: 45px;
  margin-right: 4px;
}
.no-data {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* END NO DATA */

/* START CONVERSATION DRAWER */
.conversation-drawer .ql-container {
  min-height: 406px;
}
.conversation-drawer__actions {
  margin-top: 16px;
}

.conversation-drawer.big-convo {
  margin-left: 90px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.ant-drawer-content-wrapper .big-convo{
  height: 95vh;
}
.conversation-drawer__actions .ant-btn-ghost {
  margin-right: 16px;
  border-radius: 6px;
}
/* END CONVERSATION DRAWER */
.ant-empty-description {
  color: rgba(0, 0, 0, 0.25);
}

.empty-home-leads-table.ant-table-wrapper .ant-table-tbody tr td {
  border: none;
}

.empty-home-leads-table .ant-table-body {
  overflow-y: hidden !important;
}
.app-empty .ant-empty-image {
  height: auto !important;
}

.custom-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='grey' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}

.custom-border:hover {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23009eef' stroke-width='1' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
}
.cursor-pointer{
  cursor: pointer;
}


@import './styles/login.css';